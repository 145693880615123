.amount-cell {
  font-family: Inconsolata, Roboto !important;
  font-weight: 500 !important;
}

.bonus-cell {
  color: #1e9c5f !important;
}

.penalty-cell {
  color: #E64A19 !important;
}
