.bikes-switch {
    position: absolute;
    top: 80px;
    margin-left: 16px;
    margin-right: 16px;
    padding-left: 5px;
    padding-right: 4px;
    z-index: 10;
}

