
/* Styles */
html {
  height: 100%;
}

body {
  font: 14px 'Roboto', sans-serif;
  color: #4d4d4d;
  background: #fafafa;
  margin: 0;
  line-height: 1.2;
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  font-smoothing: antialiased;
  min-height: 100%;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.relative{
  position: relative
}

.absolute{
  position: absolute
}

.flex-row{
  display: flex;
  flex-direction: row;
}

.flex-row-reverse{
  display: flex;
  flex-direction: row-reverse;
}

.flex{
  flex: 1;
}

.flex-grow{
  flex-grow: 1;
}

.flex-wrap{
  flex-wrap: wrap;
}

.flex-nowrap{
  flex-wrap: nowrap;
}

.justify-space-between{
  justify-content: space-between;
}

.justify-center {
  justify-content: center
}

.justify-flex-end{
  justify-content: flex-end;
}

.align-items-center{
  align-items: center;
}

.align-items-end{
  align-items: flex-end;
}

.align-items-start{
  align-items: flex-start;
}

.align-self-end {
  align-self: flex-end;
}

.align-self-center {
  align-self: center;
}

.align-self-start {
  align-self: flex-start;
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-auto {
  overflow: auto;
}

.page {
  padding-top: 64px; // todo: obslyzyc zmniejszajacy sie appbar przy mniejszych rozdzielczosciach
  height: calc(100% - 64px);
}

.panel {
  margin: 16px;
}

.panel-right {
  margin: 16px;
  margin-right: 32px;
}

.margin-right {
  margin-right: 16px;
}

.margin-right-large {
  margin-right: 32px;
}

.margin-right-small {
  margin-right: 8px;
}

.margin-left {
  margin-left: 16px;
}

.margin-horizontal {
  margin-right: 16px;
  margin-left: 16px;
}

.margin-bottom {
  margin-bottom: 16px;
}

.margin-bottom-small {
  margin-bottom: 8px;
}

.margin-bottom-large {
  margin-bottom: 32px;
}

.page.padder {
  margin: 40px;
}

.full-width {
  width: 100%;
}

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0px 1000px white inset !important;
}

.editor {
  padding: 0 16px 0 16px;
  min-height: 200px;
}

.text-disabled {
  color: #c9c9c9;
}
