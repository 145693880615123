.filters-panel {
    margin-bottom: 16px;
    & div[aria-expanded="true"] {
        opacity: 0.3;
    }
}

.trace-summary-export-gpx {
  margin-top: 5px;
}

.trace-summary {
    margin-top: 0;
}

.trace-summary-header {
    visibility: hidden;
}

.trace-summary-separator {
    background: #4d4d4d;
    width: 2px;
    margin-top: -2px;
    margin-bottom: -2px;
}

.errorIcon {
    color: #f44336 !important;
    height: 16px !important;
}

.value {
    margin-bottom: 8px !important;
}

.amount {
    font-family: Inconsolata, Roboto !important;
}

.trace-line {
    animation: stroke 30s ease infinite;
}

@keyframes stroke {
    0% {
        background-position: 0% 14%
    }
    50% {
        background-position: 100% 87%
    }
    100% {
        background-position: 0% 14%
    }
}

.progress {
    text-align: center;
    width: 100%;
    margin-top: 100px,
}
