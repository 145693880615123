.error {
    color: #f44336;
    a {
        text-decoration: underline;
        cursor: pointer;
    }
}

.statusContainer {
    margin-top: 16px;

    .inProgress, .rideStarted, .rideFailure {
        display: flex;
        align-items: center;
    }
}

.rideStartedIcon {
    color: #4CAF50;
}

.rideCanceledIcon {
    color: #F44336;
}

.progressIndicator {
    z-index: 1;
}
.dialogButton {
    margin-left: 16px !important;
}