.client-activation-status {
    background: blue;
    &.active {
        background: linear-gradient(to left, #dce35b, #D1D863);
        color: white;
    }
    &.inactive {
        background: linear-gradient(to left, #f44336, #D33D33);
        color: white;
    }
}

.client-status-active {
    background: linear-gradient(to left, #dce35b, #D1D863);
    color: white;
}

.client-status-locked {
    background: linear-gradient(to left, #ffb300, #ff8f00);
    color: #000;
}

.client-status-deleted {
    background: linear-gradient(to left, #f4511e, #dd2c00);
    color: #000;
}


.parental-consent-button {
    &.right {
        padding: 0;
        min-height: 16px;
        padding-right: 4px;
    }
    &.left {
        padding: 0;
        min-height: 16px;
        padding-left: 4px;
    }
}
