.filters-panel {
  margin-bottom: 16px;
  & div[aria-expanded="true"] {
      opacity: 0.3;
  }
}

.summary-panel {
  margin-bottom: 16px;
  padding: 16px;
  h4 {
    margin-top: 0;
  }
}

