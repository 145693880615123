.empty-state {
  font-size: 16px;
  color: #757575;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100px;

  svg {
    fill: #BDBDBD !important; // grey400
    width: 30px !important;
    height: 30px !important;
    margin-right: 16px;
  }
}
