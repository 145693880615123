.pagination-panel {
  font-family: Roboto !important;
  margin-top: 16px;
  color: rgba(0, 0, 0, 0.54);
}

.pagination-size-option {
  font-family: Roboto !important;
    .pagination-select > div > div {
        padding-right: 20px;
        padding-left: 8px;
    }
}

.pagination-section {
  font-family: Roboto !important;
  margin-right: 36px;
}

.pagination-button {
  font-family: Roboto !important;
  font-size: 15px !important;
  width: 32px !important;
  height: 32px !important;
  &.selected {
    font-weight: 700 !important;
  }
}