.config-section {
  padding: 16px;
}

.config-section-header {
  margin-top: 22px !important;
}

.actions {
  text-align: right;
}