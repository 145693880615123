.bike-details-card {
  position: absolute;
  bottom: 0px;
  margin: 16px;
  z-index: 10;
  min-width: 260px;
}

.card {
  min-width: 275;
}
.bullet {
  display: 'inline-block';
  margin: '0 2px';
  transform: 'scale(0.8)';
}
.title {
  margin-bottom: 16;
  font-size: 14;
}
.pos {
  bottom: 12,
}