#started-heatmap-legend {
  height: 16px;
  width: 200px;
  background: linear-gradient(
    to right,
    rgba(0, 255, 255, 1),
    rgba(0, 191, 255, 1),
    rgba(0, 127, 255, 1),
    rgba(0, 63, 255, 1),
    rgba(0, 0, 255, 1),
    rgba(0, 0, 223, 1),
    rgba(0, 0, 191, 1),
    rgba(0, 0, 159, 1),
    rgba(0, 0, 127, 1),
    rgba(63, 0, 91, 1),
    rgba(127, 0, 63, 1),
    rgba(191, 0, 31, 1),
    rgba(255, 0, 0, 1)
  );
}

#finished-heatmap-legend {
  height: 16px;
  width: 200px;
  background: linear-gradient(
    to right,
    rgba(102, 255, 0, 1),
    rgba(147, 255, 0, 1),
    rgba(193, 255, 0, 1),
    rgba(238, 255, 0, 1),
    rgba(244, 227, 0, 1),
    rgba(249, 198, 0, 1),
    rgba(255, 170, 0, 1),
    rgba(255, 113, 0, 1),
    rgba(255, 57, 0, 1),
    rgba(255, 0, 0, 1)
  );
}
