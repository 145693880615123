.grow {
  flex: 1 1 auto,
}

.root {
  display: 'flex';
  align-items: 'stretch';
  min-height: 100vh;
  width: 100%;
}

.drawer {
  width: 250px;
}

a {
  color: inherit;
}