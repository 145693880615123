.filter {
  margin-right: 8px;
  .label {
    font-weight: 500;
  }
  .chip {
    background: #fde900;
    margin-left: 4px;
  }
}

.filter-inputs {
  font-size: 12px;
  color: 1px solid rgba(0, 0, 0, 0.1);
}

.date-time-filter {
  > div {
    align-items: center;
  }
}

.select-filter-display {
  font-size: 12px;
  color: 1px solid rgba(0, 0, 0, 0.1);
}

.select-filter {
  height: 28px;
  > div > div {
    padding: 0px;
    display: flex;
    height: 28px;
  }
  > div > input + svg {
    display: none;
  }
}
