.header-panel {
  padding: 16px;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  button {
    margin-left: 8px;
  }
}

.section-header {
  margin-top: 22px !important;
}