.columns .column {
  display: inline-block;
}

.column {
  vertical-align: middle;
  margin-right: 5px;
}

.error {
  color: #f44336;
}
