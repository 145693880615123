.bike-parts-container {
  display: flex;
  flex-direction: row;
}

.bike-part-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.bike-part-icon {
  width: 60px;
  height: 60px;
}