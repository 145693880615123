.ql-editor {
  min-height: 200px;
}

div.ql-toolbar.ql-snow {
  border-radius: 4px 4px 0px 0px;
}

div.ql-container.ql-snow {
  border-radius: 0px 0px 4px 4px;
}

.ql-snow .ql-tooltip.ql-editing a.ql-action::after {
  content: "Zapisz" !important;
}

.ql-snow .ql-tooltip::before {
  content: "Idź do:" !important;
}

.ql-snow .ql-tooltip a.ql-action::after {
  content: "Edytuj" !important;
}

.ql-snow .ql-tooltip a.ql-remove::before {
  content: "Usuń" !important;
}

.ql-snow .ql-tooltip[data-mode="link"]::before {
  content: "Wprowadź link:" !important;
}
.ql-snow .ql-picker.ql-header .ql-picker-label::before,
.ql-snow .ql-picker.ql-header .ql-picker-item::before {
  content: "Normalny" !important;
}
.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="1"]::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="1"]::before {
  content: "Nagłówek 1" !important;
}
.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="2"]::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="2"]::before {
  content: "Nagłówek 2" !important;
}
.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="3"]::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="3"]::before {
  content: "Nagłówek 3" !important;
}
.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="4"]::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="4"]::before {
  content: "Nagłówek 4" !important;
}
.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="5"]::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="5"]::before {
  content: "Nagłówek 5" !important;
}
.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="6"]::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="6"]::before {
  content: "Nagłówek 6" !important;
}
