.bikes-filter-bar {
    position: absolute;
    top: 80px;
    margin-left: 16px;
    margin-right: 16px;
    z-index: 10;
    display: flex;
}

.filter-form-control {
    min-width: 120px !important;
    margin: 16px !important;
}
